<template>
  <v-card :loading="isLoading">
    <div class="d-block w-100 m-3">
      <rs-return-to />
    </div>
    <v-card-title>Gönderilen Bildirim(SMS - Mobil) Detayı</v-card-title>
    <v-card-text v-if="formData.notifiable">
      <v-row dense>
        <v-col sm="4">
          <rs-text-field
            label="Oluşturulma Tarihi"
            :value="new Date(formData.created_at).toLocaleDateString()"
            readonly
          />
        </v-col>
        <v-col sm="4">
          <rs-text-field
            label="Güncelleme Tarihi"
            :value="new Date(formData.updated_at).toLocaleDateString()"
            readonly
          />
        </v-col>
        <v-col sm="4">
          <rs-text-field :value="formData.id" label="Bildirim Id" readonly />
        </v-col>
      </v-row>
      <v-row cols="12" dense>
        <v-col sm="4">
          <rs-text-field
            :value="formData.notifiable.name"
            label="Alıcı Ad Soyad"
            readonly
          />
        </v-col>
        <v-col sm="4">
          <rs-text-field
            :value="formData.notifiable.email"
            label="Alıcı E-posta"
            readonly
          />
        </v-col>
        <v-col sm="4">
          <!-- BUG: rs-tel-field throws exception -->
          <rs-text-field
            label="Alıcı Telefon"
            v-model="formData.notifiable.phone"
            readonly
          />
        </v-col>
        <v-col sm="4">
          <rs-text-field
            :value="formData.notifiable.identity_number"
            label="Alıcı Kimlik No"
            readonly
          />
        </v-col>
        <v-col sm="4">
          <rs-text-field
            :value="formData.notifiable.passport_no"
            label="Alıcı Pasaport No"
            readonly
          />
        </v-col>
        <v-col sm="4">
          <rs-text-field
            :value="formData.notifiable.position"
            label="Alıcı Ünvan"
            readonly
          />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <rs-textarea
            label="Gönderilen İçerik"
            :value="formData.data.text"
            readonly
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "SendedNotificationDetail",
  props: {
    /**
     * Notification ID
     * @type {Number}
     */
    id: {
      type: Number,
      required: true,
    },
  },
  watch: {
    id(newValue) {
      if (newValue) {
        this.load();
      }
    },
  },
  data() {
    return {
      isLoading: true,
      formData: {},
    };
  },
  mounted() {
    if (this.id) {
      this.$nextTick(() => this.load());
    }
  },
  methods: {
    load() {
      if (!this.id) {
        return false;
      }

      this.$api
        .get("notifications", this.id)
        .then((response) => {
          this.formData = response.data.data;
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>
